import React, {useEffect} from 'react';
import {
    setupIonicReact,
    IonApp,
    IonLoading,
    IonRouterOutlet,
    IonSplitPane
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Redirect, Route, Switch} from 'react-router-dom';

import Login from '../pages/Auth/Login';

import Menu from './Menu';
import AdminTabs from './AdminTabs';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';
import '../theme/style.css';

import {useAuth} from './AuthContext';

setupIonicReact();

const App: React.FC = () => {

    const {authInfo, initialize} = useAuth()!;

    useEffect(() => {
        !authInfo?.initialized && (async () => await initialize())();

    }, [authInfo, initialize]);

    if (!authInfo || !authInfo.initialized) {
        return (
            <IonApp>
                 <IonLoading isOpen={true}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />
            </IonApp>
        );
    } else {
        return (
            <IonApp> {
                (authInfo.loggedIn !== true) ? (
                    <IonReactRouter>
                            <IonRouterOutlet>
                                <Switch>
                                    <Route path="/login" exact={true} component={Login}/>
                                    <Redirect from="/" to="/login" exact={true}/>
                                    <Redirect from="*" to="/login"/>
                                </Switch>
                            </IonRouterOutlet>
                    </IonReactRouter>
                ) : (
                    <IonReactRouter>
                        <IonSplitPane contentId="main">
                            <Menu/>
                            <IonRouterOutlet id="main">
                                <Switch>
                                   
                                    {authInfo.user.role === 'Admin' &&
                                    (
                                        <Route path="/app" component={AdminTabs}/>
                                    )}

                                   
                                    <Redirect from="/" to="/app" exact={true}/>
                                    <Redirect from="*" to="/app"/>
                                </Switch>
                            </IonRouterOutlet>

                        </IonSplitPane>
                    </IonReactRouter>
                )
            } </IonApp>
        );
    }
};

export default App;
