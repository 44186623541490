import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonAlert,
    IonCard,
    IonCardContent,
    IonLoading,
    IonText,
    IonIcon
} from '@ionic/react';
import { lockClosedOutline, mailOutline } from 'ionicons/icons';
import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {useAuth} from "../../components/AuthContext";

const Login: React.FC = () => {
    let {logIn} = useAuth()!;
    const history = useHistory();
    const [email, setEmail] = useState < string > ("");
    const [password, setPassword] = useState < string > ("");
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);

    const handleLogin = () => {

        if (!email) {
            setMessage("Please enter registered Email ID");
            setIserror(true);
            setShowLoading(false);
            return;
        }

        if (!password || password.length < 6) {
            setMessage("Thats an invalid password!");
            setIserror(true);
            setShowLoading(false);
            return;
        }

        setShowLoading(true);

        logIn(email, password).then(res => {
            history.push("/app");
            setShowLoading(false);
        }).catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Unable to connect to server');
            setIserror(true);
            setShowLoading(false);
        });
    };

    return (
        <IonPage>

            <IonContent fullscreen={true}
                className="ion-text-center ion-padding grey-shade">

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="first-alert"
                    header="Login Error"
                    message={message}
                    buttons={
                        ["Retry"]
                    }/>
                    
                <IonGrid className="ion-no-padding">
                   <IonRow>
                        <IonCol offsetSm="2" offset="0" sizeSm="8" size="12">

                            <IonGrid>
                                <IonRow className="mt-30">
                                    <IonCol className="ion-text-center">
                                        <img alt="Logo" src="assets/images/logo.png" width="160" height="160" />
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol className="ion-text-center">
                                        <IonText className="auth-title">{process.env.REACT_APP_NAME}</IonText>
                                    </IonCol>
                                </IonRow>
                                
                                <IonRow className="ion-justify-content-center mt-30">
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonIcon slot="start"
                                                    icon={mailOutline}
                                                    color="primary" 
                                                    className='mt-20'/>
                                                    <IonLabel position="stacked" className="auth-label">
                                                        EMAIL</IonLabel>
                                                    <IonInput type="text"
                                                        value={email}
                                                        className="auth-input"
                                                        placeholder='Email'
                                                        onIonChange={
                                                            (e) => setEmail(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol>
                                        <IonCard>
                                            <IonCardContent className="ion-no-padding">
                                                <IonItem>
                                                    <IonIcon slot="start"
                                                        icon={lockClosedOutline}
                                                        color="primary" 
                                                        className='mt-20'/>
                                                    <IonLabel position="stacked" className="auth-label">
                                                        PASSWORD</IonLabel>
                                                    <IonInput type="password"
                                                        value={password}
                                                        placeholder='Your Password'
                                                        className="auth-input"
                                                        onIonChange={
                                                            (e) => setPassword(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-justify-content-center mt-30">
                                    <IonCol className='ion-padding'>
                                        <IonButton expand="block" onClick={handleLogin}>Login</IonButton>
                                       
                                    </IonCol>
                                </IonRow>
                                </IonGrid>
                               
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Login;
