import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios, { AxiosRequestConfig } from 'axios';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Filesystem, Directory } from '@capacitor/filesystem';
import FileOpener from "../../../components/FileOpener";
import {useAuth} from '../../../components/AuthContext';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer';
import { trash } from 'ionicons/icons';

interface ParamTypes {
    billID: string
}

const BillDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {billID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [fileName, setFileName] = useState < string > ("");
    const [fileExt, setFileExt] = useState < string > ("");
    const [amount, setAmount] = useState < number > ();
    const [remarks, setRemarks] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [tags, setTags] = useState < string[] > ([]);
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());

    const options = {
        share: false, // default is false
        closeButton: true, // default is true
        copyToReference: false, // default is false
        headers: '',  // If this is not provided, an exception will be triggered
        piccasoOptions: { } // If this is not provided, an exception will be triggered
    };
    const formatDate = (value: string | string[]) => {
        if (typeof value === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        
      };

    const openImage = (url: string, name: string) => {
        PhotoViewer.show(url, name, options);
    }
    const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

    const getMimeType = (ext: string) => {
        let mime = "";
        if (ext === 'jpeg')
            mime = 'image/jpeg';
        if (ext === 'png')
            mime = 'image/png';
        if (ext === 'pdf')
            mime = 'application/pdf';
        return mime;
    };

    const downloadBill = async (fileName : string) => {

        const url = process.env.REACT_APP_STATIC_S3 + '/bills/' + fileName;
        const config: AxiosRequestConfig = {
            responseType: 'blob',
            headers: {
                'Cache-Control': 'no-cache'
              }
          };

          const myArray = fileName.split(".");
          const mimeType = getMimeType(myArray[1]);

        if (isPlatform('capacitor'))
        {
            setShowLoading(true);
            try {
            Filesystem.checkPermissions().then(async (res) => {
                if (res.publicStorage !== 'granted') {
                    Filesystem.requestPermissions().then(async (ress) => {
                        if (ress.publicStorage === 'denied') {
                            setMessage('Storage permission denied. You won\'t be able to download bill/voucher!');
                            setIserror(true);
                            setShowLoading(false);
                        }
                        else {
                            try {
                                const result = await axios.get(url, config);
                                if (result.data instanceof Blob)
                                {
                                    const base64 = await convertBlobToBase64(result.data) as string;

                                    const savedFile = await Filesystem.writeFile({
                                        path: fileName,
                                        data: base64,
                                        directory: Directory.External
                                    });

                                    if(savedFile.uri)
                                    {
                                        setShowLoading(false);
                                        FileOpener.open({filePath: savedFile.uri, contentType: mimeType});
                                    }
                                    else
                                    {
                                        setMessage('Unable to download bill/voucher');
                                        setIserror(true);
                                    }
                                }
                                else
                                {
                                    setMessage('Unable to download bill/voucher');
                                    setIserror(true);
                                }
                            }
                            catch(error: any) {
                                setMessage('Unable to download bill/voucher');
                                setIserror(true);
                            }
                        }
                    });
                }
                else {
                    try {
                        const result = await axios.get(url, config);
                        if (result.data instanceof Blob)
                        {
                            const base64 = await convertBlobToBase64(result.data) as string;

                            const savedFile = await Filesystem.writeFile({
                                path: fileName,
                                data: base64,
                                directory: Directory.External
                            });

                            if(savedFile.uri)
                            {
                                setShowLoading(false);
                                FileOpener.open({filePath: savedFile.uri, contentType: mimeType});
                            }
                            else
                            {
                                setMessage('Unable to download bill/voucher');
                                setIserror(true);
                            }
                        }
                        else
                        {
                            setMessage('Unable to download bill/voucher');
                            setIserror(true);
                        }
                    }
                    catch(error: any) {
                        setMessage('Unable to download bill/voucher');
                        setIserror(true);
                    }
                }
            });

        } catch {
            setMessage('Unable to download bill/voucher');
            setIserror(true);
            
        }
        setShowLoading(false);
        }
        
    }

    const handleTags = (value : string) => {
        let inputTags = value.toLowerCase();
        let strArray: Array < string > = [];
        strArray = inputTags.split(" ");
        setTags(strArray);
    }

    const handleSubmit = () => {

        if (!mode || mode === "") {
            setMessage("Select transaction mode");
            setIserror(true);
            return;
        }

        if (!remarks || remarks === "") {
            setMessage("Transaction Remarks is mandatory");
            setIserror(true);
            return;
        }

        if (!amount || amount === 0) {
            setMessage("Amount can't be 0");
            setIserror(true);
            return;
        }


        if(!tags.length)
        {
            setMessage("Atleast 1 Tag is required");
            setIserror(true);
            return;
        }

    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upBill = (mode: string, remarks: string, amount: number, tags: string, dated: string | string[]) => {
            return new Promise((resolve, reject) => {
              api.put('/transactions/'+billID, { mode, remarks, tags, dated, 'amount': -amount }).then(res => {
        
                return resolve(res.data.remarks);
                
              }).catch(err => reject(err));
    
            });
          }
      
      
        upBill(mode, remarks, amount, JSON.stringify(tags), selectedDate)
          .then(data => {
    
              setPass(data+' has been updated.');
              setIspass(true);
              setShowLoading(false);
            
          })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');
            setIserror(true);
            setShowLoading(false);
          });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/transactions/' + billID);

                setRemarks(result.data.remarks);
                setMode(result.data.mode);
                setAmount(Math.abs(result.data.amount));
                setTags(result.data.tags);

                setFileName(result.data.filename);
                const myArray = result.data.filename.split(".");
                setFileExt(myArray[1]);

                setSelectedDate(result.data.dated);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, billID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">Bill or Voucher</IonTitle>
                    <IonButtons slot="end">
                        <IonButton><IonIcon icon={trash} color="primary" /></IonButton>
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Bill or Voucher</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonCard className="ion-text-center">
                                <IonCardHeader>
                                    <IonCardSubtitle>Bill / Voucher Preview</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    {(fileExt) && ((fileExt === 'jpeg') || (fileExt === 'png')) &&
                                        (
                                            <img onClick={() => openImage(`${process.env.REACT_APP_STATIC_S3}/bills/${fileName}`, remarks)}
                                            src={`${process.env.REACT_APP_STATIC_S3}/bills/${fileName}`} 
                                            alt="Bill Preview" 
                                            width="320" />
                                       )
                                    }
                                    {(fileExt) && (fileExt === 'pdf') &&
                                        (<div className="pdf-preview">
                                            <div className="vertical-container">
                                                <div className="vertical-center"> 
                                                    <img onClick={() => downloadBill(fileName)} src="assets/images/pdf-icon.png" alt="PDF" />
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                </IonCardContent>        
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    {fileName && (
                    <IonRow className="ion-margin-top">
                        <IonCol className="ion-padding-horizontal">
                            <IonButton expand="block" color="dark" onClick={() => downloadBill(fileName)}>Download Bill or Voucher</IonButton> 

                        </IonCol>
                    </IonRow>
                )}
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                Bill or Voucher Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                          
                            <IonList className="ion-no-padding">

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Remarks</IonLabel>
                                    <IonInput type="text"
                                        placeholder='Transaction Remarks'
                                        value={remarks}
                                        onIonChange={
                                            (e) => setRemarks(e.detail.value!)
                                    }></IonInput>
                                </IonItem>
                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Amount</IonLabel>
                                    <IonInput type="number"
                                        value={amount}
                                        placeholder='Transaction Amount'
                                        onIonChange={
                                            (e) => setAmount(parseInt(e.detail.value!))
                                    }></IonInput>
                                </IonItem>

                                
                                <IonItem detail={false}
                                    lines='full'
                                    button={false}
                                    className='form-item'>
                                    <IonLabel position="fixed" color="secondary">Mode</IonLabel>
                                    <IonSelect value={mode}
                                        placeholder="Select Mode"
                                        onIonChange={
                                            (e) => setMode(e.detail.value)
                                    }>
                                        
                                        <IonSelectOption value="cash">cash</IonSelectOption>
                                        <IonSelectOption value="online">online</IonSelectOption>      
                                        
                                    </IonSelect>
                                </IonItem>
                                <IonItem detail={false}
                                    lines='full'
                                    button={true}
                                    id="open-date-input">
                                    <IonLabel position="fixed" color="secondary">Date</IonLabel>
                                    <IonInput value={formatDate(selectedDate)} readonly={true} />
                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                        initialBreakpoint={0.7} breakpoints={[0, 0.7]} handle={false}>
                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                            <IonToolbar>
                                                <IonTitle>Transaction Date</IonTitle>
                                                
                                            </IonToolbar>
                                            <IonDatetime
                                                value={selectedDate}
                                                presentation="date"
                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                showDefaultButtons={true}
                                            />
                                        </IonContent>
                                        
                                    </IonModal>
                                </IonItem>

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Tags</IonLabel>
                                    <IonInput type="text"
                                        placeholder='Associated Tags'
                                        value={tags ? tags.join(' ') : ''}
                                        onIonChange={
                                            (e) => handleTags(e.detail.value!)
                                    }></IonInput>
                                </IonItem>

                            </IonList>
                                
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Update Bill or Voucher</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default BillDetail;
