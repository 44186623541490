import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, card, cash, eyeOutline, search } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory, useParams} from 'react-router-dom';

interface ParamTypes {
    tag?: string
}

interface Transaction {
    _id: string,
    mode: string,
    amount: number,
    remarks: string,
    dated: Date,
    filename: string,
    tags: string[],
    createdAt: Date
}


interface ParamTypes {
    instituteID: string
}

const SearchBills: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {tag} = useParams < ParamTypes > ();
    const {instituteID} = useParams < ParamTypes > ();
    const [trans, setTrans] = useState < Transaction[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [searchText, setSearchText] = useState < string > ("");

    const getDate = (utcdate: Date) => {
        const bookDate = new Date(utcdate);
        return bookDate.toLocaleDateString('en-GB');
    }

    const handleSearch = () => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        if (!searchText || searchText === "") {
            setMessage("Enter Keywords to search");
            setIserror(true);
            return;
        }

        setShowLoading(true);

        const fetchUp = async () => {

            try {
                const result = await api.post(`/transactions/search/`, { 'instituteID': instituteID, 'searchText': searchText});
                setTrans(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                if (tag)
                {
                    const result = await api.post(`/transactions/search/`, { 'instituteID': instituteID, 'searchText': tag});
                    setTrans(result.data);
                    setSearchText(tag)
                }

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo, instituteID, tag]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/${instituteID}/home`} />
                    </IonButtons>
                    <IonTitle color="secondary">Search Bill or Voucher</IonTitle>
                    <IonButtons slot="end">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Search Bill or Voucher</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="ion-padding">
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                        
                                                <IonItem detail={false}
                                                            lines='none'
                                                            button={false}
                                                            
                                                            >
                                                    <IonInput type="text"
                                                        value={searchText}
                                                        placeholder="Keywords to search"
                                                        onIonChange={
                                                            (e) => setSearchText(e.detail.value!)
                                                    }></IonInput>
                                                           
                                                        <IonButtons slot="end">
                                                            <IonButton onClick={handleSearch}> <IonIcon 
                                                                icon={search}
                                                                color='secondary'/></IonButton>
                                                                
                                                                
                                                        </IonButtons>
                                                                    
                                                </IonItem>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                    </IonRow>
                   
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Search Results
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                          
                             {(trans.length > 0) ? (trans.map((tran) => {
                                    return (
                                        <IonCard className='border-radius-10' key={tran._id}>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonGrid className="ion-no-padding ion-no-margin">
                                                    <IonRow className="tran-pad tran-row">
                                                        <IonCol size="6" className=''>
                                                            <IonText className='tran-label'>Transaction Date<br/></IonText>
                                                            <IonText className='tran-text'>
                                                                {getDate(tran.dated)}
                                                                </IonText>
                                                        

                                                        </IonCol>
                                                        <IonCol size="6" className='ion-text-right'>
                                                            <IonText className='tran-label'>Upload Date<br/></IonText>
                                                            <IonText className='tran-text'>
                                                                {getDate(tran.createdAt)}
                                                                </IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className='ion-padding border-top'>
                                                        <IonCol size="2">
                                                            <IonIcon className='tran-icon'
                                                                icon={(tran.mode === 'cash') ? cash : card}
                                                                color='danger' />
                                                                
                                                                <IonText className='tran-mode'><br/> {tran.mode} </IonText>
                                                            </IonCol>
                                                            <IonCol size="9" className='ion-padding-horizontal'>
                                                                <IonText className='action-text'> 
                                                                Rs. {Math.abs(tran.amount)} <br/> 
                                                                </IonText>
                                                                <IonText className='tran-rem'> 
                                                                    {tran.remarks}<br/>
                                                                </IonText>
                                                               
                                                                    {(tran.tags.length > 0) && (tran.tags.map((tag, i) => {
                                                                        return (<IonText className='tran-tag' key={i} onClick={() => history.push(`/app/${instituteID}/bills/search/${tag}`)}>{tag}</IonText>)
                                                                        })
                                                                    )}
                                                                    
                                                              
                                                            </IonCol>
                                                            <IonCol size="1">
                                                                {(tran.filename) && (
                                                                    <IonIcon className='tran-icon mt-10'
                                                                        icon={eyeOutline}
                                                                        color='dark'
                                                                        onClick={() => history.push(`/app/bill/${tran._id}`)}
                                                                    />
                                                                )}
                                                        </IonCol>
                                                    </IonRow>
                                                   
                                                
                                                </IonGrid>
                                            </IonCardContent>
                                        </IonCard>
                                        
                                    )
                                })) : ( 
                                    <IonCard>

                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                    <IonLabel className="list-title"><h3>No Bills/Vouchers</h3></IonLabel>
                                                

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )
                                } 
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default SearchBills;
