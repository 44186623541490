import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { addCircleOutline, alertCircleOutline, construct } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';

interface User {
    _id: string,
    name: string,
    role: string,
    isActive: boolean
}

const Users: React.FC = () => {
    const {authInfo} = useAuth()!;

    const [users, setUsers] = useState < User[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }
    useEffect(() => {

        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {

            try {
                const result = await api.get(`/users`);
                setUsers(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle color="secondary">Users</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Users</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="ion-padding-top">
                        <IonCol>
                            <IonCard className='action-card-one' routerLink={`/app/users/add`}>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                    button={true}
                                    detail={true}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>Add New User</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                           
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    All Users
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                             {(users.length > 0) ? (users.map((user, i) => {
                                    return (
                                        <IonCard key={user._id} className={user.role === 'master' ? 'ion-margin-bottom circular-card' : 'ion-margin-bottom announce-card'}
                                                        routerLink={`/app/user/${user._id}`} >
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                        
                                                <IonRow>
                                                    <IonCol size="9" offset="1" className='planner-col ion-padding-bottom'>
                                                        
                                                        <IonBadge className={user.role === 'master' ? 'circular-badge' : 'announce-badge'}>{user.role === 'master'  ? 'Master' : 'Stockist'}</IonBadge>
           
                                                            <p>{user.name}</p>
   

                                                    </IonCol>
                                                    <IonCol size="1">
                                                        <div className="vertical-container">
                                                            <div className="vertical-center"> 
                                                                <IonIcon className='circular-icon'
                                                                    icon={construct}
                                                                    color='dark' />
                                                            </div>
                                                        </div>
                                                          
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                    )
                                })) : ( 
                                    <IonCard className='border-radius-10'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="red-title"><h3>No Users</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )
                                } 
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Users;
