import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import { addCircle, arrowUndoCircle, toggle } from 'ionicons/icons';

interface ParamTypes {
    itemID: string
}

interface Size {
    _id: string,
    name: string,
    isActive: boolean,
    stock: number
}

const ItemDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {itemID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [sizes, setSizes] = useState < Size[] > ([]);
    const [refreshed, setRefreshed] = useState < number > (0);
  
    const handleSection = (section: string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const toggleItem = (isActive: string) => {
        return new Promise((resolve, reject) => {

            api.put(`/items/${itemID}`, { 'isActive': section }).then(res => {
    
                return resolve(res.data.name);
                
              }).catch(err => reject(err));

        });
      }

      if ((active !== "") && (section !== active))
      {
        setShowLoading(true);

        toggleItem(section)
        .then(data => {
            let msg: string = "";
          if (section === 'enable')
          {
              setActive("enable");
              msg = data+ " is now Active.";
          }
              
          if (section === 'disable')
          {
              setActive("disable");
              msg = data+ " is now Inactive.";
          }
              
  
          setPass(msg);
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });
      }

    }

    const handleName = (name: string, index: number) => {

        let comps = [...sizes];
        comps[index].name = name;
        setSizes(comps);
    
    }

    const handleDelete = (index: number) => 
    {
        let comps = [...sizes];
        comps[index].isActive = !comps[index].isActive;
        setSizes(comps);
    }

    const handleAdd = () => {
        let r = (Math.random() + 1).toString(36).substring(7);
        let comps = [...sizes];
        comps.push({_id: r, name: '', isActive: true, stock: 0});
        setSizes(comps);
    }


    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage("Name is a mandatory field!");
            setIserror(true);
            return;
        }

        if (!sizes || (sizes.length < 1)) {
            setMessage("Assign atleast 1 size!");
            setIserror(true);
            return;
        }
    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upItem = (name: string, sizes: Size[]) => {
          return new Promise((resolve, reject) => {
            api.put('/items/'+itemID, { name, sizes }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
    
      upItem(name, sizes)
        .then(data => {
  
            setPass(data+' has been updated.');
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    
    useEffect(() => {

        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            try {
                const result = await api.get('/items/' + itemID);

                setName(result.data.name);
                setSizes(result.data.sizes);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, itemID, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app/items"/>
                    </IonButtons>
                    <IonTitle color="secondary">Manage Item</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Manage Item</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                     <IonRow className="mt-10">
                        <IonCol>
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                <IonTitle className='list-title'>
                                                    Current Status 
                                                </IonTitle>
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>Active</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>Inactive</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-horizontal ion-padding-top">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value! )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable" >
                                                        <IonLabel>Make Active</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>Make Inactive</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={() => history.push(`/app/item/tran/${itemID}`)}
                                expand="block" color="dark">Item Transactions</IonButton>

                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                                <IonTitle className='list-title'>
                                    <h3>Item Details</h3>
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonList className="ion-no-padding">
                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Name</IonLabel>
                                    <IonInput type="text"
                                        value={name}
                                        onIonChange={
                                            (e) => setName(e.detail.value !)
                                    }></IonInput>
                                </IonItem>
                            </IonList>
                              
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonCard className='border-radius-10' color='tertiary'>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonGrid>
                                        <IonRow className="mt-05">
                                            <IonCol size="8" className='ion-padding-horizontal'>
                                                Item Sizes
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-right'>
                                               
                                               <IonIcon className='tran-icon'
                                                   icon={arrowUndoCircle}
                                                   color='primary'
                                                   onClick={() => setRefreshed(Math.random())}
                                               />
                                           
                                           
                                            </IonCol>
                                            <IonCol size="2" className='ion-text-center'>
                                               
                                                    <IonIcon className='tran-icon'
                                                        icon={addCircle}
                                                        color='primary'
                                                        onClick={handleAdd}
                                                    />
                                                
                                                
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                {sizes && (sizes.length > 0) && (
                <>
                 
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList>
                                            {sizes.map((size, i) => {
                                                return (
                                                    <IonItem detail={false}
                                                        lines='full'
                                                        button={false}
                                                        key={size._id}>
          
                                                            <IonInput type="text"
                                                                    placeholder='Size Name'
                                                                    value={size.name}
                                                                    onIonChange={
                                                                        (e) => handleName(e.detail.value!, i)
                                                                }
                                                                readonly={!size.isActive}
                                                                ></IonInput>
                                                            <IonButtons slot="end">
                                                                <IonButton onClick={() => handleDelete(i)}> 
                                                                    <IonIcon
                                                                    icon={toggle}
                                                                    color={size.isActive ? "success" : "danger"}
                                                                    />
                                                                </IonButton>
                                                                
                                                            </IonButtons>
                                                            
                                                    </IonItem>
                                                )
                                                
                                                })
                                            }

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                         </IonCol>
                    </IonRow>

                </>
                )}

                   
                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Save Changes</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default ItemDetail;
