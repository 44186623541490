import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonHeader,
    IonToolbar,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonListHeader,
    IonButtons,
    IonButton,
    IonRow,
    IonCol,
    IonGrid,
    isPlatform
} from '@ionic/react';
import {useLocation, useHistory} from 'react-router-dom';
import {
    personOutline,
    personSharp,
    logOutOutline,
    logOutSharp,
    homeSharp,
    homeOutline,
    cashOutline,
    cashSharp,
    receiptOutline,
    receiptSharp,
    barChartOutline,
    barChartSharp,
    closeSharp,
    calendarNumberOutline,
    calendarNumberSharp,
    pricetagsOutline,
    pricetagsSharp,
    buildOutline,
    buildSharp,
    albumsOutline,
    albumsSharp
} from 'ionicons/icons';
import {useAuth} from "./AuthContext";

import '../theme/menu.css';

const Menu: React.FC = () => {
    const {logOut} = useAuth()!;
    const location = useLocation();
    const history = useHistory();

    return (
        <IonMenu side="end" menuId='auth' contentId="main" type="overlay">

            <IonHeader>
                <IonToolbar color="primary">
                    <IonRow className="pb-08">
                        <IonCol size="2" className="ion-text-right">
                                <img src={`assets/images/logowhite.png`} alt={process.env.REACT_APP_NAME} width="50" className='mt-15'/>
                        </IonCol>
                        <IonCol size="10" className={isPlatform('desktop') ? 'mt-20' : 'mt-10'}>
                            <IonNote>{process.env.REACT_APP_NAME}</IonNote>
                        </IonCol>
                    </IonRow>
                    <IonButtons slot="end">
                        <IonMenuToggle>
                            <IonButton><IonIcon size="large" icon={closeSharp}></IonIcon></IonButton>
                        </IonMenuToggle>  
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>

                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol>
                            
                        <IonList id="menu-list">
                            <IonListHeader>Dashboard</IonListHeader>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/home' ? 'selected' : ''
                                    }
                                    routerLink='/app/home'
                                    routerDirection="forward"
                                    lines="none" 
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={homeOutline}
                                        md={homeSharp}/>
                                    <IonLabel>Home</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/inventory' ? 'selected' : ''
                                    }
                                    routerLink='/app/inventory'
                                    routerDirection="forward"
                                    lines="none" 
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={albumsOutline}
                                        md={albumsSharp}/>
                                    <IonLabel>Inventory</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/items' ? 'selected' : ''
                                    }
                                    routerLink='/app/items'
                                    routerDirection="forward"
                                    lines="none" 
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={pricetagsOutline}
                                        md={pricetagsSharp}/>
                                    <IonLabel>Manage Items</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/users' ? 'selected' : ''
                                    }
                                    routerLink='/app/users'
                                    routerDirection="forward"
                                    lines="none" 
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={personOutline}
                                        md={personSharp}/>
                                    <IonLabel>Manage Users</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/transactions' ? 'selected' : ''
                                    }
                                    routerLink='/app/transactions'
                                    routerDirection="forward"
                                    lines="none" 
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={receiptOutline}
                                        md={receiptSharp}/>
                                    <IonLabel>Manage Transactions</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                           
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/outputs' ? 'selected' : ''
                                    }
                                    routerLink='/app/outputs'
                                    routerDirection="forward"
                                    lines="none" 
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={buildOutline}
                                        md={buildSharp}/>
                                    <IonLabel>Manage Outputs</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/payments' ? 'selected' : ''
                                    }
                                    routerLink='/app/payments'
                                    routerDirection="forward"
                                    lines="none" 
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={cashOutline}
                                        md={cashSharp}/>
                                    <IonLabel>Manage Payments</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/dues' ? 'selected' : ''
                                    }
                                    routerLink='/app/dues'
                                    routerDirection="forward"
                                    lines="none" 
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={calendarNumberOutline}
                                        md={calendarNumberSharp}/>
                                    <IonLabel>View Dues</IonLabel>
                                </IonItem>
                            </IonMenuToggle>

                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem className={
                                        location.pathname === '/app/transactions/reports' ? 'selected' : ''
                                    }
                                    routerLink='/app/transactions/reports'
                                    routerDirection="forward"
                                    lines="none" 
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={barChartOutline}
                                        md={barChartSharp}/>
                                    <IonLabel>View Reports</IonLabel>
                                </IonItem>
                            </IonMenuToggle>


                        </IonList>

                        <IonList id="account-list">
                            <IonListHeader>Account</IonListHeader>
      
                          
                            <IonMenuToggle menu='auth' autoHide={false}>
                                <IonItem button={true} onClick={
                                        async () => {
                                            await logOut();
                                            history.replace('/login');
                                        }
                                    }
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={logOutOutline}
                                        md={logOutSharp}/>
                                    <IonLabel>Logout</IonLabel>
                                </IonItem>
                            </IonMenuToggle>

                        </IonList>

                    
                       
                            
                            </IonCol>
                        </IonRow>
                    </IonGrid>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
