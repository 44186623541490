import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { addCircleOutline, alertCircleOutline, cash, trash } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {Link} from 'react-router-dom';

interface Payment {
    _id: string,
    mode: string,
    user: User,
    amount: number,
    remarks: string,
    dated: Date
}

interface User {
    userID: string,
    name: string
}


const Payments: React.FC = () => {
    const {authInfo} = useAuth()!;
    const [trans, setTrans] = useState < Payment[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [selectedTran, setSelectedTran] = useState < string > ("");

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    function getDate(utcdate: Date)
    {
        const bookDate = new Date(utcdate);
        return bookDate.toLocaleDateString('en-GB');
    }

    const handleDelete = () => {
    
        setShowLoading(true);
  
          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
      const deleteApp = () => {
          return new Promise((resolve, reject) => {
  
            api.delete(`/payments/${selectedTran}`).then(res => {
      
                  return resolve(true);
                  
                }).catch(err => reject(err));
  
          });
        }
  
      deleteApp()
        .then(() => {
  
          setPass('Payment has been removed.');
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/payments`);
                setTrans(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle color="secondary">Payments</IonTitle>
                    <IonButtons slot="end">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Payments</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh No!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={'Confirm!'}
                    message={`Are you sure you want to delete this payment?`}
                    buttons={[
                        {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: 'Yes',
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                    />

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header="Alert!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

               

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60">

                    <IonRow className="ion-padding-top">
                        <IonCol>
                            <IonCard className='action-card-one' routerLink={`/app/payments/add`}>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                    button={true}
                                    detail={true}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>Add New Payment</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                           
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Recently Added
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                          <IonList className='ion-no-padding'>
                          {(trans.length > 0) ? (trans.map((tran, i) => {
                              if (tran.mode !== "due")
                              {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={tran._id}
                                            className='transact-item'
                                           >
                                            <IonIcon slot="start"
                                                icon={cash}
                                                color={tran.mode === "debit"  ? 'danger' : 'success'}
                                                />
                                            <IonLabel className="transact-label ion-text-wrap">
                                                <h3>{tran.remarks}</h3>
                                                {(tran.mode === "debit") && (

                                                    <p>Paid On: {tran.dated && getDate(tran.dated)}<br/>
                                                       Paid To: <Link to={`/app/user/${tran.user.userID}`}>{tran.user.name}</Link></p>
                                                )}
                                                 {(tran.mode === "credit") && (

                                                    <p>Received On: {tran.dated && getDate(tran.dated)}<br/>
                                                    Received By: <Link to={`/app/user/${tran.user.userID}`}>{tran.user.name}</Link></p>
                                                    )}
                                            </IonLabel>
                                            <IonLabel className="ion-text-right transact-amt">
                                                <h3>&#8377; {Math.abs(tran.amount)}</h3>
                                                
                                            </IonLabel>
                                            <IonIcon slot="end"
                                                icon={trash}
                                                color='danger'
                                                onClick={() => {
                                                    setSelectedTran(tran._id);
                                                    setShowAlert(true);
                                                }}
                                                />
                                        </IonItem>
                                    )
                                }
                                else
                                {
                                    return null;
                                }
                                })) : ( 
                                    <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>No Payments</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )
                                }</IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Payments;
