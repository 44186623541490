import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import { addCircleOutline, alertCircleOutline, construct } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';

interface ItemInterface {
    _id: string,
    name: string,
    sizes: Size[],
    isActive: boolean
}

interface Size {
    _id: string,
    name: string,
    isActive: boolean,
    stock: number
}

const Items: React.FC = () => {
    const {authInfo} = useAuth()!;
    const [items, setItems] = useState < ItemInterface[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    useEffect(() => {

        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {

            try {
                
                const result = await api.get(`/items`);
                setItems(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            
        };

        fetchUp();
        setShowLoading(false);

    }, [authInfo, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle color="secondary">Manage Items</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Manage Items</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60">
                    <IonRow className="ion-padding-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                            <IonCard className='action-card-one' routerLink={`/app/items/add`}>
                                <IonCardContent className="ion-no-padding ion-no-margin">
                                    <IonItem lines="none"
                                    button={true}
                                    detail={true}
                                    >
                                        <IonIcon icon={addCircleOutline}
                                            slot="start"
                                            color="secondary" />
                                        <IonLabel className="action-title-one"><h3>Add New Item</h3></IonLabel>

                                    </IonItem>
                                </IonCardContent>
                            </IonCard>
                           
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-margin-top">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                                <IonTitle className='list-title'>
                                    Items
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-vertical">
                        <IonCol offsetXl="3" offsetMd="2" offset="0" sizeXl="6" sizeMd="8" size="12">
                          
                             {(items.length > 0) ? (items.map((item, i) => {
                                    return (
                                        <IonCard key={item._id} className="ion-margin-vertical class-card" routerLink={`/app/item/${item._id}`} >

                                            <IonCardContent className="ion-no-padding">
                                                  <IonRow>
                                                        <IonCol size="11">
                                                            <p><span className="class-name pl-08" >{item.name}</span></p>
                                                            
                                                        
                                                            <p><span className="class-info-2 pl-08">Total Sizes: {item.sizes.length}</span></p>
                                                        </IonCol>
                                                        <IonCol size="1">
                                                            <p><IonIcon icon={construct} size="small" color="dark" className='mt-15' />
                                                            </p>
                                                          
                                                        </IonCol>
                                                    </IonRow>
                                                            
                                            </IonCardContent>
                                        </IonCard>
                                    )
                                })) : ( 
                                    <IonCard className='border-radius-10'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="red-title"><h3>No Items</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )
                                } 
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Items;
