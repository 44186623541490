import {
    IonAlert,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail
} from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {useAuth} from "../../components/AuthContext";
import { useHistory } from 'react-router-dom';


const AdminHome: React.FC = () => {
    const {authInfo, logOut} = useAuth()!;
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [isexpired, setIsexpired] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [isold, setIsold] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [dues, setDues] = useState < number > (0);
    const [payments, setPayments] = useState < number > (0);

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }
   
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
             
                const result = await api.get(`/payments/reports`);
                
                setPayments(result.data.sales-result.data.purchases);

                const due = await api.get(`/users/reports`);
                setDues(due.data.dues);

            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                    if (error.response.data.message === 'expired')
                    {
                        setIsexpired(true);
                    }
                    else
                    {
                        setMessage(error.response.data.message);
                        setIserror(true);
                    }
                    
                }   
                else 
                {
                    setMessage('Unable to connect to our server. Please check your internet connection and try again.');
                    setIserror(true);
                }
            }
            setShowLoading(false);
        };


        const fetchVersion = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/version-check`);
                if (parseInt(result.data) > parseInt(process.env.REACT_APP_VERSION!))
                {
                    setIsold(true); 
                }
                else
                {
                   
                    fetchData();

                }
                
            } catch (error: any) {  
                if (error.response !== undefined) 
                {
                        setMessage(error.response.data.message);
                        setIserror(true); 
                }   
                else 
                {
                    setMessage('Unable to connect to our server. Please check your internet connection and try again.');
                    setIserror(true);
                }
            }
            setShowLoading(false);
        };

       
        fetchVersion();
        


    }, [authInfo, refreshed]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="end">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle color="secondary">Hello! {authInfo.user.name}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Hello! {authInfo.user.name}</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert
                    isOpen={isexpired}
                    onDidDismiss={async () => {
                        await logOut();
                        history.replace("/login");
                    }}
                    header="Session Expired"
                    message="Please login again to continue using our services"
                    buttons={["Logout"]}
                />

                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => setIserror(false)}
                    header="Alert"
                    message={message}
                    buttons={["Close"]}
                />

                <IonAlert
                    isOpen={isold}
                    backdropDismiss={false}
                    header="Old Version"
                    message="This version is no longer supported. Please update to latest version."
                />

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

               
                <IonGrid className='ion-padding mb-60'>
                    <IonRow className="ion-padding-vertical">
                        <IonCol>
                        <IonCard className='action-card-one pb-16 ion-no-margin'>
                            <IonCardHeader>
                                    <IonRow>
                                        <IonCol>
                                            <IonCardSubtitle className='info-subtitle pt-08'>Accounts Overview</IonCardSubtitle>
                                        </IonCol>
                                    </IonRow>
                                </IonCardHeader>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol size="6">
                                        <IonCard className="stat-card-2 ion-no-margin">
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>
                                                            <IonText className='stat-head'>&#8377; {dues}</IonText>
                                                    </IonCol>
                                                  
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <p className='stat-subhead'>Amount<br/>Overdue</p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>

                                    </IonCol>
                                    <IonCol size="6">
                                        <IonCard className="stat-card-4 ion-no-margin">
                                            <IonCardContent>
                                                <IonRow>
                                                    <IonCol>
                                                            <IonText className='stat-head'>&#8377; {payments}</IonText>
                                                    </IonCol>
                                                    
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        <p className='stat-subhead'>Cash In<br/>Hand</p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
  
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className='mt-30 mb-30'>

                        <IonCol size="4">
                            <IonCard className="dash-card-2 ion-no-margin" routerLink={`/app/users`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt="Manage Users" src="assets/images/admn.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title ion-no-padding">
                                                Manage<br/>Users
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                      
                    
                        <IonCol size="4">
                            <IonCard className="dash-card-5 ion-no-margin" routerLink={`/app/items`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt="Manage Items" src="assets/images/items.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title ion-no-padding">
                                                Manage<br/>Items
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    
                        
                        
                     
                        <IonCol size="4">
                            <IonCard className="dash-card-6 ion-no-margin" routerLink={`/app/transactions`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className="ion-no-padding">
                                                <img alt="Manage Purchases" src="assets/images/transactions.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title ion-no-padding">
                                                Purchase<br/>Sale
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                     
                      
                      
                        <IonCol size="4">
                            <IonCard className="dash-card-1 ion-no-margin" routerLink={`/app/outputs`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt="Manage Outputs" src="assets/images/outputs.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title ion-no-padding">
                                               Manage<br/>Outputs
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                       
                        <IonCol size="4">
                            <IonCard className="dash-card-4 ion-no-margin" routerLink={`/app/payments`}>
                                <IonCardContent className="ion-padding">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol className='ion-no-padding'>
                                                <img alt="Manage Payments" src="assets/images/payments.png"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol className="dash-title ion-no-padding">
                                                Manage<br/>Payments
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>

                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default AdminHome;