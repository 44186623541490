import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, person, ticket } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';


interface User {
    _id: string,
    name: string,
    role: string,
    due: number,
    isActive: boolean
}

const ViewDues: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [users, setUsers] = useState < User[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");

   
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/users/masters`);
                setUsers(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle color="secondary">View Overdues</IonTitle>
                    <IonButtons slot="end">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">View Overdues</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding mb-60">

                  
                    <IonRow className="ion-margin">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Master Name (Overdue)
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-top">
                        <IonCol>
                          <IonList className='ion-no-padding'>
                             {(users.length > 0) ? (users.map((user, i) => {
                                    return (
                                        <IonItem detail={true}
                                            detailIcon={person}
                                            lines='full'
                                            button={true}
                                            key={user._id}
                                            className={((i%2) === 0) ? '' : 'alt-item'}
                                            onClick={
                                                () => history.push(`/app/user/${user._id}`)
                                            }>
                                            <IonIcon slot="start"
                                                icon={ticket}
                                                color={
                                                    (user.due > 0) ? 'danger' : 'success'
                                                }/>
                                            <IonLabel className="list-label">
                                                <h3>{user.name} (Rs. {user.due})</h3>
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonItem lines="none">
                                        <IonIcon icon={alertCircleOutline}
                                        slot="start" color="danger" />
                                        <IonLabel className="list-label"><h3>No Users</h3></IonLabel>

                                    </IonItem>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default ViewDues;
