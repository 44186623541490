import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';

interface User {
    _id: string,
    name: string,
    role: string,
    isActive: boolean
}

const AddPayment: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");

    const [amount, setAmount] = useState < number > ();
    const [remarks, setRemarks] = useState < string > ("");
    const [userID, setUserID] = useState < string > ("");
    const [userName, setUserName] = useState < string > ("");
    const [users, setUsers] = useState < User[] > ([]);
    const [mode, setMode] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());


    const formatDate = (value: string | string[]) => {
        if (typeof(value) === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        
      };

    const handleUser = (userID : string) => {
        let selectedUser: User | undefined;
        selectedUser = users.find(x => x._id === userID);

        if (selectedUser !== undefined)
        {
            setUserName(selectedUser.name);
            setUserID(userID);
        }
           
    }


    const handleSubmit = () => {

        if (!mode || mode === "") {
            setMessage("Select Payment Type");
            setIserror(true);
            return;
        }

        if (!userID || userID === "") {
            setMessage("Select User");
            setIserror(true);
            return;
        }

        if (!remarks || remarks === "") {
            setMessage("Transaction Remarks is mandatory");
            setIserror(true);
            return;
        }

        if (!amount || amount === 0) {
            setMessage("Amount can't be 0");
            setIserror(true);
            return;
        }


        setShowLoading(true);

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const addTran = ( mode: string, userID: string, userName: string, remarks: string, amount: number, dated: string | string[] ) => {
          return new Promise((resolve, reject) => {
            api.post('/payments/new', { mode, userID, userName, remarks, amount, dated }).then(res => {
              return resolve(res.data);
            }).catch(err => reject(err));
  
          });
        }

       
            addTran(mode, userID, userName, remarks, amount, selectedDate)
            .then(data => {
            
                setPass('Payment added.');
                setIspass(true);
                setShowLoading(false);
            })
            .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');
    
                setIserror(true);
                setShowLoading(false);
            });
        
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/users`);
                setUsers(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/payments`} />
                    </IonButtons>
                    <IonTitle color="secondary">Add Payment</IonTitle>
                    <IonButtons slot="end">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Add Payment</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => history.push(`/app/payments`)
                    }
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>


                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Payment Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonList className="ion-no-padding">

                                <IonItem detail={false}
                                    lines='full'
                                    button={true}
                                    id="open-date-input">
                                    <IonLabel position="fixed" color="secondary">Date</IonLabel>
                                    <IonInput value={formatDate(selectedDate)} readonly={true} />
                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                        initialBreakpoint={0.7} breakpoints={[0, 0.7]} handle={false}>
                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                            <IonToolbar>
                                                <IonTitle>Transaction Date</IonTitle>
                                                
                                            </IonToolbar>
                                            <IonDatetime
                                                value={selectedDate}
                                                presentation="date"
                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                showDefaultButtons={true}
                                                size="cover"
                                            />
                                        </IonContent>
                                        
                                    </IonModal>
                                </IonItem>
                               

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="fixed" color="secondary">Type</IonLabel>
                                            <IonSelect value={mode}
                                                    placeholder="Payment Type"
                                                    onIonChange={
                                                        (e) => setMode(e.detail.value)
                                                }>
                                                <IonSelectOption value={`credit`}>Credit (Paid by)</IonSelectOption>
                                                <IonSelectOption value={`debit`}>Debit (Paid To)</IonSelectOption>
                                    </IonSelect>
                                </IonItem>

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="fixed" color="secondary">User</IonLabel>
                                            <IonSelect value={userID}
                                                    placeholder="User"
                                                    onIonChange={
                                                        (e) => handleUser(e.detail.value)
                                                }>
                                                { users.map((user) => {
                                                        if (user.isActive)
                                                        {
                                                            return (<IonSelectOption key={user._id} value={`${user._id}`}>{user.name} ({user.role})</IonSelectOption>);
                                                        }
                                                        else
                                                        {
                                                            return null;
                                                        }
                                                    })
                                                }
                                    </IonSelect>
                                </IonItem>

                             

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Amount</IonLabel>
                                    <IonInput type="number"
                                        value={amount}
                                        placeholder='Amount'
                                        onIonChange={
                                            (e) => setAmount(parseInt(e.detail.value!))
                                    } ></IonInput>
                                </IonItem>
                           

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Remarks</IonLabel>
                                    <IonInput type="text"
                                        placeholder='Transaction Remarks'
                                        value={remarks}
                                        onIonChange={
                                            (e) => setRemarks(e.detail.value!)
                                    }></IonInput>
                                </IonItem>
                                

                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Add Payment</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>

        </IonPage>
    );
};

export default AddPayment;
