import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    RefresherEventDetail,
} from '@ionic/react';
import axios from 'axios';
import {alertCircleOutline, cash, trash } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';

interface ParamTypes {
    userID: string
}

interface Payment {
    _id: string,
    mode: string,
    amount: number,
    remarks: string,
    dated: Date
}

interface User {
    _id: string,
    name: string,
    role: string,
    due: number,
    isActive: boolean
}

const UserLedger: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {userID} = useParams < ParamTypes > ();
    const [trans, setTrans] = useState < Payment[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [user, setUser] = useState < User > ();
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [selectedTran, setSelectedTran] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");

    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }

    function getDate(utcdate: Date)
    {
        const bookDate = new Date(utcdate);
        return bookDate.toLocaleDateString('en-GB');
    }

    const handleDelete = () => {
    
        setShowLoading(true);
  
          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
      const deleteApp = () => {
          return new Promise((resolve, reject) => {
  
            api.delete(`/payments/${selectedTran}`).then(res => {
      
                  return resolve(true);
                  
                }).catch(err => reject(err));
  
          });
        }
  
      deleteApp()
        .then(() => {
  
          setPass('Payment has been removed.');
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });
        
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/users/' + userID);
                setUser(result.data);

                const pay = await api.get(`/payments/getByUser/${userID}`);
                setTrans(pay.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo, userID, refreshed]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/user/${userID}`} />
                    </IonButtons>
                    <IonTitle color="secondary">User Payments</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="white-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">User Payments</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={'Confirm!'}
                    message={`Are you sure you want to delete this payment?`}
                    buttons={[
                        {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: 'Yes',
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                    />

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header="Alert!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>


                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding">
                {user && (
                    <>
                    <IonRow className="ion-margin-vertical">
                        <IonCol>
                             <IonCard className={user.role === 'master' ? 'ion-margin-bottom circular-card' : 'ion-margin-bottom announce-card'}>
                                    <IonCardContent className="ion-no-padding ion-no-margin">
                                
                                        <IonRow>
                                            <IonCol size="8" offset="2" className='planner-col ion-padding-bottom'>
                                                
                                                <IonBadge className={user.role === 'master' ? 'circular-badge' : 'announce-badge'}>{user.role === 'master'  ? 'Master' : 'Stockist'}</IonBadge>
    
                                                    <p>{user.name}</p>


                                            </IonCol>
                                           
                                        </IonRow>

                                    </IonCardContent>
                                </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                    <IonCol>
                        <IonCard className='action-card-b'>
                            <IonCardHeader className="ion-text-center">
                                <IonCardSubtitle color='danger'>Overdue Amount</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent className="ion-text-center overdue">
                                    <IonRow>
                                        <IonCol>
                                            <h1>Rs. {user.due}</h1>
                                        </IonCol>
                                    </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                </>
                    )}
                   
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                   Payment History
                                </IonTitle>
                        </IonCol>
                        
                    </IonRow>
                    <IonRow className="mb-60">
                        <IonCol>
                          <IonList className="ion-no-padding">
                             {(trans.length > 0) ? (trans.map((tran, i) => {
                                    return (
                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}
                                            key={tran._id}
                                            className='transact-item'
                                           >
                                            <IonIcon slot="start"
                                                icon={cash}
                                                color={(tran.mode === "credit") || (tran.amount < 0)  ? 'success' : 'danger'}
                                                />
                                            <IonLabel className="transact-label ion-text-wrap">
                                                <h3>{tran.remarks}</h3>
                                                {(tran.mode === "debit") && (

                                                <p>Paid On: {tran.dated && getDate(tran.dated)}</p>
                                                )}
                                                {(tran.mode === "credit") && (

                                                <p>Received On: {tran.dated && getDate(tran.dated)}</p>
                                                )}
                                                {(tran.mode === "due") && (

                                                <p>Due Added On: {tran.dated && getDate(tran.dated)}</p>
                                                )}
                                            </IonLabel>
                                            <IonLabel className="ion-text-right transact-amt">
                                                <h3>&#8377; {Math.abs(tran.amount)}</h3>
                                                
                                            </IonLabel>
                                            {(tran.mode !== "due") && (
                                            <IonIcon slot="end"
                                                icon={trash}
                                                color='danger'
                                                onClick={() => {
                                                    setSelectedTran(tran._id);
                                                    setShowAlert(true);
                                                }}
                                                />
                                            )}
                                        </IonItem>
                                    )
                                })) : ( 
                                    <IonCard className='red-card ion-margin'>
                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                <IonLabel className="list-title"><h3>No Payments</h3></IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )
                                } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default UserLedger;
