import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';

interface ParamTypes {
    userID: string
}

const UserProfile: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {userID} = useParams < ParamTypes > ();
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [role, setRole] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");

    const handleSection = (section: string) => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const toggleUser = (isActive: string) => {
            return new Promise((resolve, reject) => {
                api.put(`/users/${userID}`, { 'isActive': section }).then(res => {

                        return resolve(res.data.name);

                  }).catch(err => reject(err));
            });
          }

        if ((active !== "") && (section !== active))
        {
            setShowLoading(true);

        toggleUser(section)
          .then(data => {
                
            setActive(section);
    
            setPass(data+' has been updated.');
            setIspass(true);
            setShowLoading(false);
          })
          .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');
    
            setIserror(true);
            setShowLoading(false);
          });
        }
    }

    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage("Name is a mandatory field!");
            setIserror(true);
            return;
        }

        if (!role || role === "") {
            setMessage("Role is a mandatory field!");
            setIserror(true);
            return;
        }

        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
          const upUser = (name: string, address: string, role: string, mobile: string) => {
          return new Promise((resolve, reject) => {
            api.put('/users/'+userID, { name, address, role, mobile }).then(res => {
               
                    return resolve(res.data.name);

            }).catch(err => reject(err));
          });
        }

      upUser(name, address, role, phone)
        .then(data => {
  
            setPass(data+' has been updated.');
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    
    useEffect(() => {
        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/users/' + userID);
                setName(result.data.name);
                setRole(result.data.role);
                setAddress(result.data.address);
                setPhone(result.data.mobile);

                if (result.data.isActive)
                    setActive("enable");
                else
                    setActive("disable");

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, userID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">User Profile</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">User Profile</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    header="Success!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-10">
                        <IonCol>
                            <IonCard className='border-radius-10 active-group'>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="8">
                                                <IonTitle className='list-title'>
                                                    Current Status 
                                                </IonTitle>
                                            </IonCol>
                                            <IonCol size="4" className='ion-text-right ion-padding-horizontal'>
                                            {(active === 'enable') ? ( <IonBadge color="success" className='ion-padding-horizontal'>Active</IonBadge>) : 
                                            ( <IonBadge color="danger" className='ion-padding-horizontal'>Inactive</IonBadge>)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-padding-top ion-padding-horizontal">
                                            <IonCol>
                                                <IonSegment value={active} onIonChange={e => handleSection( e.detail.value! )} mode="ios" className="isactive-segment">
                                                    <IonSegmentButton value="enable">
                                                        <IonLabel>Make Active</IonLabel>
                                                    </IonSegmentButton>
                                                    <IonSegmentButton value="disable">
                                                        <IonLabel>Make Inactive</IonLabel>
                                                    </IonSegmentButton>
                                                </IonSegment>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                                <IonTitle className='list-title'>
                                    User's Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonList className="ion-no-padding">

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Name</IonLabel>
                                    <IonInput type="text"
                                        value={name}
                                        placeholder="User's Name"
                                        onIonChange={
                                            (e) => setName(e.detail.value !)
                                    }></IonInput>
                                </IonItem>

                                <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">User Role</IonLabel>
                                                    <IonSelect value={role}
                                                            placeholder="User Role"
                                                            onIonChange={
                                                                (e) => setRole(e.detail.value)
                                                        }>
                                                            <IonSelectOption value="master">Master</IonSelectOption>
                                                            <IonSelectOption value="stockist">Stockist</IonSelectOption>
                                                      
                                            </IonSelect>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Mobile</IonLabel>
                                            <IonInput type="text"
                                                placeholder='10 Digits Mobile No.'
                                                value={phone}
                                                onIonChange={
                                                    (e) => setPhone(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Address</IonLabel>
                                            <IonInput type="text"
                                                placeholder='Full Address'
                                                value={address}
                                                onIonChange={
                                                    (e) => setAddress(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                </IonList>
                            </IonCol>
                    </IonRow>


                </IonGrid>

            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Update User</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default UserProfile;
