import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import axios from 'axios';
import Chart from "react-apexcharts";

interface ParamTypes {
    instituteID: string
}

const Reports: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {instituteID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [categories, setCategories] = useState < string[] > (['First', 'Second', 'Third']);
    const [incomeSeries, setIncomeSeries] = useState < number[] > ([0, 0, 0]);
    const [expenseSeries, setExpenseSeries] = useState < number[] > ([0, 0, 0]);
    const [income, setIncome] = useState < number > (0);
    const [expense, setExpense] = useState < number > (0);
    const [series1, setSeries1] = useState < number[] > ([0, 0]);
    const [series2, setSeries2] = useState < number[] > ([0, 0]);

    const series =  [{
        name: 'Income',
        data: incomeSeries
        }, {
        name: 'Expenses',
        data: expenseSeries
    }];

    const options =  {
        chart: {
            toolbar: {
              show: false
            }
        },
        colors : ['#2dd36f', '#eb445a'],
        plotOptions: {
            bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
            text: 'Rs. (thousands)'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return "Rs. " + val + " thousands"
                }
            },
            theme: 'dark'
        }
    };

    const options1 = {
        colors : ['#FFC900', '#086E7D'],
        labels: ['Cash', 'Online'],
        dataLabels: {
            enabled: false
        },
        legend: {
            position: 'bottom' as 'bottom',
            labels: {
                colors: '#777'
            },
          }
    };
         
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                    else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, instituteID]); 

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/home`} />
                    </IonButtons>
                    <IonTitle color="secondary">Financial Reports</IonTitle>
                    <IonButtons slot="end">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Financial Reports</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                Last 3 Months
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol className='ion-padding'>
                            <Chart options={options} series={series} type="bar" height={350} />
                        </IonCol>
                    </IonRow>
                  
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Reports;
