import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonDatetime,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';

interface User {
    _id: string,
    name: string,
    role: string,
    isActive: boolean
}

interface ItemInterface {
    _id: string,
    name: string,
    sizes: Size[],
    isActive: boolean
}

interface Size {
    _id: string,
    name: string,
    isActive: boolean,
    stock: number
}

const AddTransaction: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [qty, setQty] = useState < number > (0);
    const [rate, setRate] = useState < number > (0);
    const [misc, setMisc] = useState < number > (0);
    const [amount, setAmount] = useState < number > ();
    const [remarks, setRemarks] = useState < string > ("");
    const [toID, setToID] = useState < string > ("");
    const [toName, setToName] = useState < string > ("");
    const [fromID, setFromID] = useState < string > ("");
    const [fromName, setFromName] = useState < string > ("");
    const [users, setUsers] = useState < User[] > ([]);
    const [items, setItems] = useState < ItemInterface[] > ([]);
    const [sizes, setSizes] = useState < Size[] > ([]);
    const [itemID, setItemID] = useState < string > ("");
    const [itemName, setItemName] = useState < string > ("");
    const [sizeID, setSizeID] = useState < string > ("");
    const [sizeName, setSizeName] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < string | string[] > (new Date().toISOString());


    const formatDate = (value: string | string[]) => {
        if (typeof(value) === "string")
        {
            const valueDate = new Date(value);
            return valueDate.toLocaleDateString('en-GB');
        }
        
      };

    const handleQty = (q : number) => {
        setQty(q);
        setAmount((q*rate)+misc);  
    }

    const handleRate = (r : number) => {
        setRate(r);
        setAmount((qty*r)+misc);  
    }

    const handleMisc = (m : number) => {
        setMisc(m);
        setAmount((qty*rate)+m);  
    }

    const handleItem = (itemID : string) => {
        let selectedItem: ItemInterface | undefined;
        selectedItem = items.find(x => x._id === itemID);

        if (selectedItem !== undefined)
        {
            setSizes(selectedItem.sizes);
            setItemName(selectedItem.name);
            setItemID(itemID);
            setSizeID("");
            setSizeName("");
        }
    }

    const handleSize = (sizeID : string) => {
        let selectedSize: Size | undefined;
        selectedSize = sizes.find(x => x._id === sizeID);

        if (selectedSize !== undefined)
        {
            setSizeName(selectedSize.name);
            setSizeID(sizeID);
        }
           
    }

    const handleFrom = (userID : string) => {
        let selectedUser: User | undefined;
        selectedUser = users.find(x => x._id === userID);

        if (selectedUser !== undefined)
        {
            setFromName(selectedUser.name);
            setFromID(userID);
        }
           
    }

    const handleTo = (userID : string) => {
        let selectedUser: User | undefined;
        selectedUser = users.find(x => x._id === userID);

        if (selectedUser !== undefined)
        {
            setToName(selectedUser.name);
            setToID(userID);

            if (selectedUser.role === "master")
            {
                setMode("purchase-sale");
            }

            if (selectedUser.role === "stockist")
            {
                setMode("purchase");
            }
        }
           
    }

    const handleSubmit = () => {

        if (!fromID || fromID === "") {
            setMessage("Select From User");
            setIserror(true);
            return;
        }

        if (!toID || toID === "") {
            setMessage("Select To User");
            setIserror(true);
            return;
        }

        if (!itemID || itemID === "") {
            setMessage("Select Item");
            setIserror(true);
            return;
        }

        if (!sizeID || sizeID === "") {
            setMessage("Select Size");
            setIserror(true);
            return;
        }

        if (fromID === toID) {
            setMessage("From User and To User cant be same");
            setIserror(true);
            return;
        }

        if (!remarks || remarks === "") {
            setMessage("Transaction Remarks is mandatory");
            setIserror(true);
            return;
        }

        if (!qty || qty === 0) {
            setMessage("Qty can't be 0");
            setIserror(true);
            return;
        }

        if (!rate || rate === 0) {
            setMessage("Rate can't be 0");
            setIserror(true);
            return;
        }

        if (!amount || amount === 0) {
            setMessage("Amount can't be 0");
            setIserror(true);
            return;
        }


        setShowLoading(true);

        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: parseInt(process.env.REACT_APP_API_TO!),
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const addTran = ( mode: string, toID: string, toName: string, fromID: string, fromName: string, itemID: string, itemName: string, sizeID: string, sizeName: string, remarks: string, qty: number, rate: number, misc: number, amount: number, dated: string | string[] ) => {
          return new Promise((resolve, reject) => {
            api.post('/transactions/add', { mode, toID, toName, fromID, fromName, itemID, itemName, sizeID, sizeName, remarks, qty, rate, misc, amount, dated }).then(res => {
              return resolve(res.data);
            }).catch(err => reject(err));
  
          });
        }

       
            addTran(mode, toID, toName, fromID, fromName, itemID, itemName, sizeID, sizeName, remarks, qty, rate, misc, amount, selectedDate)
            .then(data => {
            
                setPass('Transaction added.');
                setIspass(true);
                setShowLoading(false);
            })
            .catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');
    
                setIserror(true);
                setShowLoading(false);
            });
        
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {
            setShowLoading(true);
            try {
                const result = await api.get(`/users`);
                setUsers(result.data);

                const item = await api.get(`/items`);
                setItems(item.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/transactions`} />
                    </IonButtons>
                    <IonTitle color="secondary">Add Transaction</IonTitle>
                    <IonButtons slot="end">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Add Transaction</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => history.push(`/app/transactions`)
                    }
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>


                <IonGrid className="ion-no-padding">

                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    Transaction Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonList className="ion-no-padding">

                                <IonItem detail={false}
                                    lines='full'
                                    button={true}
                                    id="open-date-input">
                                    <IonLabel position="fixed" color="secondary">Date</IonLabel>
                                    <IonInput value={formatDate(selectedDate)} readonly={true} />
                                    <IonModal trigger="open-date-input" showBackdrop={true} 
                                        initialBreakpoint={0.7} breakpoints={[0, 0.7]} handle={false}>
                                        <IonContent className={isPlatform('ios') ? 'grey-shade' : 'white-shade'}>
                                            <IonToolbar>
                                                <IonTitle>Transaction Date</IonTitle>
                                                
                                            </IonToolbar>
                                            <IonDatetime
                                                value={selectedDate}
                                                presentation="date"
                                                onIonChange={ev => setSelectedDate(ev.detail.value!)}
                                                showDefaultButtons={true}
                                                size="cover"
                                            />
                                        </IonContent>
                                        
                                    </IonModal>
                                </IonItem>
                               

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="fixed" color="secondary">From</IonLabel>
                                            <IonSelect value={fromID}
                                                    placeholder="From User"
                                                    onIonChange={
                                                        (e) => handleFrom(e.detail.value)
                                                }>
                                                { users.map((user) => {
                                                        if (user.isActive)
                                                        {
                                                            return (<IonSelectOption key={user._id} value={`${user._id}`}>{user.name} ({user.role})</IonSelectOption>);
                                                        }
                                                        else
                                                        {
                                                            return null;
                                                        }
                                                    })
                                                }
                                    </IonSelect>
                                </IonItem>

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="fixed" color="secondary">To</IonLabel>
                                            <IonSelect value={toID}
                                                    placeholder="To User"
                                                    onIonChange={
                                                        (e) => handleTo(e.detail.value)
                                                }>
                                                { users.map((user) => {
                                                        if (user.isActive)
                                                        {
                                                            return (<IonSelectOption key={user._id} value={`${user._id}`}>{user.name} ({user.role})</IonSelectOption>);
                                                        }
                                                        else
                                                        {
                                                            return null;
                                                        }
                                                    })
                                                }
                                    </IonSelect>
                                </IonItem>


                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                        <IonLabel position="fixed" color="secondary">Item</IonLabel>
                                            <IonSelect value={itemID}
                                                    placeholder="Select Item"
                                                    onIonChange={
                                                        (e) => handleItem(e.detail.value)
                                                }>
                                                { items.map((item) => {
                                                        if (item.isActive)
                                                        {
                                                            return (<IonSelectOption key={item._id} value={`${item._id}`}>{item.name}</IonSelectOption>);
                                                        }
                                                        else
                                                        {
                                                            return null;
                                                        }
                                                    })
                                                }
                                    </IonSelect>
                                </IonItem>


                                {itemID && itemID !== "" && (
                                    <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">Size</IonLabel>
                                                    <IonSelect value={sizeID}
                                                            placeholder="Select Size"
                                                            onIonChange={
                                                                (e) => handleSize(e.detail.value)
                                                        }>
                                                        { (sizes.length > 0) && sizes.map((size) => {
                                                                if (size.isActive)
                                                                {
                                                                    return (<IonSelectOption key={size._id} value={`${size._id}`}>{size.name}</IonSelectOption>);
                                                                }
                                                                else
                                                                {
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                            </IonSelect>
                                        </IonItem>
                                        )
                                }

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Qty</IonLabel>
                                    <IonInput type="number"
                                        value={qty}
                                        placeholder='Item Qty'
                                        onIonChange={
                                            (e) => handleQty(parseInt(e.detail.value!))
                                    }></IonInput>
                                </IonItem>

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Rate</IonLabel>
                                    <IonInput type="number"
                                        value={rate}
                                        placeholder='Item Rate'
                                        onIonChange={
                                            (e) => handleRate(parseInt(e.detail.value!))
                                    }></IonInput>
                                </IonItem>

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Misc</IonLabel>
                                    <IonInput type="number"
                                        value={misc}
                                        placeholder='Misc'
                                        onIonChange={
                                            (e) => handleMisc(parseInt(e.detail.value!))
                                    }></IonInput>
                                </IonItem>

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Amount</IonLabel>
                                    <IonInput type="number"
                                        value={amount}
                                        placeholder='Qty x Rate + Misc'
                                        readonly={true} ></IonInput>
                                </IonItem>
                           

                                <IonItem detail={false}
                                    lines='full'
                                    button={false}>
                                    <IonLabel position="fixed" color="secondary">Remarks</IonLabel>
                                    <IonInput type="text"
                                        placeholder='Transaction Remarks'
                                        value={remarks}
                                        onIonChange={
                                            (e) => setRemarks(e.detail.value!)
                                    }></IonInput>
                                </IonItem>
                                

                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Add Transaction</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>

        </IonPage>
    );
};

export default AddTransaction;
