import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import React, { useState} from 'react';
import {useAuth} from '../../../components/AuthContext';
import {useHistory} from 'react-router-dom';
import axios from 'axios';

const AddUser: React.FC = () => {
    const { authInfo} = useAuth()!;
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");

    const [role, setRole] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");


    const handleSubmit = () => {

        if (!name || name === "") {
            setMessage("Name is a mandatory field!");
            setIserror(true);
            return;
        }

        if (!role || role === "") {
            setMessage("Role is a mandatory field!");
            setIserror(true);
            return;
        }

        setShowLoading(true);
            
            const api = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                timeout: parseInt(process.env.REACT_APP_API_TO!),
                headers: {
                    'Authorization': 'Bearer ' + authInfo.token
                }
            });

            const addUser = (name: string, address: string, mobile: string, role: string) => {
                return new Promise((resolve, reject) => {
                  api.post('/users/add', { name, role, address, mobile }).then(res => {
            
                    return resolve(res.data.name);
                    
                  }).catch(err => reject(err));
        
                });
              }        
          

              addUser(name, address, phone, role)
              .then(data => {
        
                  setPass(data+' has been added.');
                  setIspass(true);
                  setShowLoading(false);
                
              })
              .catch(error => {
                  if (error.response !== undefined)
                    setMessage(error.response.data.message);
                else
                    setMessage('Please try again afer sometime.');
                setIserror(true);
                setShowLoading(false);
              }); 
        
      }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/users`} />
                    </IonButtons>
                    <IonTitle color="secondary">Add User</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Add User</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => history.push(`/app/users`)
                    }
                    header="Success!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>


                <IonGrid className="ion-no-padding">
                    
                    <IonRow className="mt-30">
                        <IonCol>
                                <IonTitle className='list-title'>
                                    User's Details
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                    <IonList className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Name</IonLabel>
                                            <IonInput type="text"
                                            placeholder="User's Name"
                                                value={name}
                                                onIonChange={
                                                    (e) => setName(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                                <IonLabel position="fixed" color="secondary">User Role</IonLabel>
                                                    <IonSelect value={role}
                                                            placeholder="User Role"
                                                            onIonChange={
                                                                (e) => setRole(e.detail.value)
                                                        }>
                                                            <IonSelectOption value="master">Master</IonSelectOption>
                                                            <IonSelectOption value="stockist">Stockist</IonSelectOption>
                                                      
                                            </IonSelect>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Mobile</IonLabel>
                                            <IonInput type="text"
                                                placeholder='10 Digits Mobile No.'
                                                value={phone}
                                                onIonChange={
                                                    (e) => setPhone(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='full'
                                            button={false}>
                                            <IonLabel position="fixed" color="secondary">Address</IonLabel>
                                            <IonInput type="text"
                                                placeholder='Full Address'
                                                value={address}
                                                onIonChange={
                                                    (e) => setAddress(e.detail.value!)
                                            }></IonInput>
                                        </IonItem>

                                </IonList>
                            </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
            <IonFooter className='ion-padding footer-shade'>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                className="first-button"
                                fill="clear"
                                expand="block">Add User</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default AddUser;
