import {
  IonRouterOutlet,
} from "@ionic/react";

import React from 'react';
import { Redirect, Route, Switch } from "react-router";

import AdminHome from "../pages/Home/AdminHome";

import Inventory from "../pages/Admin/Item/Inventory";
import Items from "../pages/Admin/Item/Items";
import AddItem from "../pages/Admin/Item/AddItem";
import ItemDetail from "../pages/Admin/Item/ItemDetail";
import ItemTransactions from "../pages/Admin/Item/ItemTransactions";
import ItemOutputs from "../pages/Admin/Item/ItemOutputs";

import Users from "../pages/Admin/User/Users";
import AddUser from "../pages/Admin/User/AddUser";
import UserDetail from "../pages/Admin/User/UserDetail";
import UserLedger from "../pages/Admin/User/UserLedger";
import UserTransactions from "../pages/Admin/User/UserTransactions";
import UserProfile from "../pages/Admin/User/UserProfile";

import Payments from "../pages/Admin/Payment/Payments";
import AddPayment from "../pages/Admin/Payment/AddPayment";
import ViewDues from "../pages/Admin/Payment/ViewDues";

import TransactionHome from "../pages/Admin/Transaction/TransactionHome";
import AddTransaction from "../pages/Admin/Transaction/AddTransaction";
import SearchTransactions from "../pages/Admin/Transaction/SearchTransactions";
import TransactionDetail from "../pages/Admin/Transaction/TransactionDetail";
import ReceiptDetail from "../pages/Admin/Transaction/ReceiptDetail";
import Reports from "../pages/Admin/Transaction/Reports";
import Outputs from "../pages/Admin/Output/Outputs";
import AddOutput from "../pages/Admin/Output/AddOutput";
import OutputDetail from "../pages/Admin/Output/OutputDetail";


const AdminTabs: React.FC = () => {
  return (
      <IonRouterOutlet>
        <Switch>
          <Route path="/app/home" exact={true} component={AdminHome} />

          <Route path="/app/inventory" exact={true} component={Inventory} />
          <Route path="/app/item/:itemID" exact={true} component={ItemDetail} />
          <Route path="/app/items" exact={true} component={Items} />
          <Route path="/app/items/add" exact={true} component={AddItem} />
          <Route path="/app/item/output/:tranID" exact={true} component={ItemOutputs} />
          <Route path="/app/item/tran/:itemID" exact={true} component={ItemTransactions} />

          <Route path="/app/users" exact={true} component={Users} />
          <Route path="/app/users/add" exact={true} component={AddUser} />
          <Route path="/app/user/:userID" exact={true} component={UserDetail} />
          <Route path="/app/user/profile/:userID" exact={true} component={UserProfile} />
          <Route path="/app/user/ledger/:userID" exact={true} component={UserLedger} />
          <Route path="/app/user/transactions/:userID" exact={true} component={UserTransactions} />
          
          <Route path="/app/transactions" exact={true} component={TransactionHome} />
          <Route path="/app/transactions/add" exact={true} component={AddTransaction} />
          <Route path="/app/transactions/search" exact={true} component={SearchTransactions} />  
          <Route path="/app/transactions/search/:tag" exact={true} component={SearchTransactions} /> 
          <Route path="/app/transaction/:tranID" exact={true} component={TransactionDetail} />
          <Route path="/app/receipt/:receiptID" exact={true} component={ReceiptDetail} />
          <Route path="/app/transactions/reports" exact={true} component={Reports} />

          <Route path="/app/outputs" exact={true} component={Outputs} />
          <Route path="/app/outputs/add" exact={true} component={AddOutput} />
          <Route path="/app/output/:outputID" exact={true} component={OutputDetail} />

          <Route path="/app/payments/add" exact={true} component={AddPayment} />
          <Route path="/app/payments" exact={true} component={Payments} />
          <Route path="/app/dues" exact={true} component={ViewDues} />


          <Redirect exact={true} path="/app" to={`/app/home`} />
          <Redirect from="*" to="/app"/>
        </Switch>
      </IonRouterOutlet>
  );
};

export default AdminTabs;
