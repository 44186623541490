import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
    RefresherEventDetail,
    isPlatform,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useParams, useHistory, Link} from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import { alertCircleOutline, eyeOutline, receipt, ticket, trash } from 'ionicons/icons';

interface ParamTypes {
    itemID: string
}

interface Transaction {
    _id: string,
    mode: string,
    to: ToInfo,
    from: FromInfo,
    size: SizeInfo,
    remarks: string,
    qty: number,
    rate: number,
    misc: number,
    amount: number,
    dated: Date,
    tags: string[],
    filename?: string
}
interface ToInfo {
    toID: string,
    name: string
}
interface FromInfo {
    fromID: string,
    name: string
}
interface SizeInfo {
    sizeID: string,
    name: string
}

const ItemTransactions: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {itemID} = useParams < ParamTypes > ();
    const [trans, setTrans] = useState < Transaction[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [sizes, setSizes] = useState < number > (0);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [selectedTran, setSelectedTran] = useState < string > ("");
    const [refreshed, setRefreshed] = useState < number > (0);
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    
    const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setRefreshed(Math.random());
        event.detail.complete();
    }
    const getDate = (utcdate: Date) => {
        const bookDate = new Date(utcdate);
        return bookDate.toLocaleDateString('en-GB');
    }

    const handleDelete = () => {
    
        setShowLoading(true);
  
          const api = axios.create({
              baseURL: process.env.REACT_APP_API_URL,
              timeout: parseInt(process.env.REACT_APP_API_TO!),
              headers: {
                  'Authorization': 'Bearer ' + authInfo.token
              }
          });
  
      const deleteApp = () => {
          return new Promise((resolve, reject) => {
  
            api.delete(`/transactions/${selectedTran}`).then(res => {
      
                  return resolve(true);
                  
                }).catch(err => reject(err));
  
          });
        }
  
      deleteApp()
        .then(() => {
  
          setPass('Transaction has been removed.');
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

    useEffect(() => {

        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            try {
                const result = await api.get('/items/' + itemID);

                setName(result.data.name);
                setSizes(result.data.sizes.length);

                const tran = await api.get(`/transactions/getByItem/${itemID}`);
                setTrans(tran.data);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, refreshed, itemID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref={`/app/item/${itemID}`}/>
                    </IonButtons>
                    <IonTitle color="secondary">Item Transactions</IonTitle>
                    <IonButtons slot="end">
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Item Transactions</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    header={'Confirm!'}
                    message={`Are you sure you want to delete this transaction?`}
                    buttons={[
                        {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                        },
                        {
                        text: 'Yes',
                        handler: () => {
                            handleDelete();
                        }
                        }
                    ]}
                    />

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => {
                            setIspass(false);
                            setRefreshed(Math.random());
                        }
                    }
                    header="Alert!"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={isPlatform('ios') ? "lines" : "circular"}></IonRefresherContent>
                </IonRefresher>

                <IonGrid className="ion-no-padding mb-60">
                   
                    <IonRow className='ion-margin-top'>
                        <IonCol>
                            <IonCard className="ion-margin-vertical class-card" >

                                <IonCardContent className="ion-no-padding">
                                    <IonRow>
                                            <IonCol size="11">
                                                <p><span className="class-name pl-08" >{name}</span></p>
                                                
                                            
                                                <p><span className="class-info-2 pl-08">Total Sizes: {sizes}</span></p>
                                            </IonCol>
                                           
                                        </IonRow>
                                                
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                          
                             {(trans.length > 0) ? (trans.map((tran) => {
                                    return (
                                        <IonCard className='border-radius-10' key={tran._id}>
                                            <IonCardContent className="ion-no-padding ion-no-margin">
                                                <IonGrid className="ion-no-padding ion-no-margin">
                                                    <IonRow className="tran-pad tran-row">
                                                        <IonCol size="6" className=''>
                                                            <IonText className='tran-label'>Date<br/></IonText>
                                                            <IonText className='tran-text'>
                                                                {getDate(tran.dated)}
                                                                </IonText>
                                                        

                                                        </IonCol>
                                                        <IonCol size="6" className='ion-text-right'>
                                                            <IonText className='tran-label'>Size<br/></IonText>
                                                            <IonText className='tran-text'>
                                                                    {tran.size.name}
                                                                </IonText>
                                                        </IonCol>
                                                    </IonRow>
                                                    <IonRow className='ion-padding border-top'>
                                                        <IonCol size="2">
                                                            <IonIcon className='tran-icon'
                                                                icon={(tran.mode === 'sale') ? receipt : ticket}
                                                                color={(tran.mode === 'sale') ? 'success' : 'danger'} />
                                                                
                                                                <IonText className='tran-mode'><br/> {tran.mode} </IonText>
                                                            </IonCol>
                                                            <IonCol size="9" className='ion-padding-horizontal'>
                                                                <IonRow>
                                                                    <IonCol>
                                                                        <IonText className='action-text'> 
                                                                            From: <Link to={`/app/user/${tran.from.fromID}`}>{tran.from.name}</Link> <br/> 
                                                                            To: <Link to={`/app/user/${tran.to.toID}`}>{tran.to.name}</Link> <br/> 
                                                                        </IonText>
                                                                        <IonText className='action-text'> 
                                                                            {tran.qty} X {tran.rate} + {tran.misc} = Rs. {Math.abs(tran.amount)} <br/> 
                                                                        </IonText>
                                                                       
                                                                        <IonText className='tran-rem'> 
                                                                            {tran.remarks}<br/>
                                                                        </IonText>
                                                                    </IonCol>
                                                                </IonRow>
                                                                {(tran.tags.length > 0) && (
                                                                <IonRow>
                                                                    <IonCol>
                                                                        {(tran.tags.map((tag, i) => {
                                                                            return (<IonText className='tran-tag' key={i} >{tag}</IonText>)
                                                                            })
                                                                        )}
                                                                    </IonCol>
                                                                </IonRow>
                                                                )}
                                                                     
                                                            {(tran.mode === 'purchase') && (
                                                                <IonRow className="ion-margin ion-text-center">
                                                                        <IonCol>
                                                                            <IonButton color="warning" size="small" onClick={() => history.push(`/app/item/output/${tran._id}`) }>View Outputs</IonButton>
                                                                        </IonCol>
                                                                        
                                                                </IonRow>
                                                            )}
                                                            </IonCol>
                                                            <IonCol size="1">
                                                                {(tran.filename) && (
                                                                    <IonIcon className='tran-icon mt-10'
                                                                        icon={eyeOutline}
                                                                        color='dark'
                                                                        onClick={() => null}
                                                                    />
                                                                )}
                                                                  <IonIcon className='tran-icon mt-10'
                                                                        icon={trash}
                                                                        color='danger'
                                                                        onClick={() => {
                                                                            setSelectedTran(tran._id);
                                                                            setShowAlert(true);
                                                                        }}
                                                                    />
                                                        </IonCol>
                                                    </IonRow>
                                                   
                                                
                                                </IonGrid>
                                            </IonCardContent>
                                        </IonCard>
                                        
                                    )
                                })) : ( 
                                    <IonCard className='border-radius-10'>

                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"
                                                    color="danger" />
                                                    <IonLabel className="red-title"><h3>No Transactions</h3></IonLabel>
                                                

                                            </IonItem>
                                        </IonCardContent>
                                    </IonCard>
                                )
                                } 
                        </IonCol>
                    </IonRow>

                    
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ItemTransactions;
