import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {useAuth} from '../../../components/AuthContext';
import { download } from 'ionicons/icons';

interface ParamTypes {
    receiptID: string
}

const ReceiptDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {receiptID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [amount, setAmount] = useState < number > ();
    const [remarks, setRemarks] = useState < string > ("");
    const [mode, setMode] = useState < string > ("");
    const [student, setStudent] = useState < string > ("");
    const [institute, setInstitute] = useState < string > ("");
    const [address, setAddress] = useState < string > ("");
    const [phone, setPhone] = useState < string > ("");
    const [mail, setMail] = useState < string > ("");
    const [fileName, setFileName] = useState < string > ("");
    const [selectedDate, setSelectedDate] = useState < Date > ();

    const getDate = (utcdate: Date) => {
        const bookDate = new Date(utcdate);
        return bookDate.toLocaleDateString('en-GB');
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: parseInt(process.env.REACT_APP_API_TO!),
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            setShowLoading(true);
            try {
                const result = await api.get('/transactions/' + receiptID);

                setRemarks(result.data.remarks);
                setMode(result.data.mode);
                setAmount(Math.abs(result.data.amount));
                setSelectedDate(result.data.dated);

                if (result.data.student)
                {
                    setStudent(result.data.student.name);
                }

                const insti = await api.get(`/institutes/${authInfo.user.instituteID}`);
                setInstitute(insti.data.name);
                setAddress(insti.data.address);
                setPhone(insti.data.phone);
                setMail(insti.data.email);
                setFileName(insti.data.logo);

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, receiptID]);
    
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/app"/>
                    </IonButtons>
                    <IonTitle color="secondary">Receipt</IonTitle>
                    <IonButtons slot="end">
                        <IonButton><IonIcon icon={download} color="primary" /></IonButton>
                       <IonMenuButton color="secondary"/>
                   </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Receipt</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    cssClass="first-loading"
                    spinner="circular"
                    message="Please Wait..."
                />

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    header="Oh no!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding white-shade mb-60">
                    <IonRow className="rec-logo">
                        <IonCol size="3" className="ion-text-center">
                            <div className="vertical-container">
                                <div className="vertical-center"> 
                            {fileName && (fileName !== "") ?
                                (<img src={`${process.env.REACT_APP_STATIC_S3}/images/${fileName}`} alt="Logo" width="70" />)
                                : (<img src={`assets/images/institute.png`} alt="Logo" width="70" />)
                            }

                                </div>
                            </div>
                        </IonCol>
                        <IonCol size="8" className=''>
                           <p className="logo-text">{institute}</p>
                           <p className="logo-address">{address}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-horizontal mt-30">
                        <IonCol size="3">
                            <IonText className='rec-label'>RECEIPT #</IonText>
                        </IonCol>
                        <IonCol size="9">
                            <IonText className='rec-label-1'>{receiptID}</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-horizontal">
                        <IonCol size="3">
                            <IonText className='rec-label'>DATE: </IonText>
                        </IonCol>
                        <IonCol size="9">
                            <IonText className='rec-label-1'>
                                {selectedDate && getDate(selectedDate)}
                                </IonText>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding tran-row ion-margin-top">
                        <IonCol size="9" className=''>
                            <IonText className='rec-title'>Description</IonText>

                        </IonCol>
                        <IonCol size="3" className='ion-text-right'>
                            <IonText className='rec-title'>Amount</IonText>
                          
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding mt-30">
                        <IonCol size="9" className=''>
                            <IonText className='rec-text'>{remarks}</IonText>

                        </IonCol>
                        <IonCol size="3" className='ion-text-right'>
                            <IonText className='rec-text'>{amount}/-</IonText>
                          
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-padding-horizontal mt-30">
                        <IonCol size="5" className=''>
                            <IonText className='rec-label'>MODE OF PAYMENT</IonText>
                        </IonCol>
                        <IonCol size="7" >
                            <IonText className='rec-paid'>{mode}</IonText>
                        </IonCol>
                    </IonRow>
                    {student && (
                    <IonRow className="ion-padding-horizontal mt-10">
                        <IonCol size="5" className=''>
                            <IonText className='rec-label'>NAME OF STUDENT</IonText>
                        </IonCol>
                        <IonCol size="7">
                            <IonText className='rec-paid'>{student}</IonText>
                        </IonCol>
                    </IonRow>
                    )}

                    <IonRow className="ion-padding mt-30 rec-row">

                            <IonCol className='ion-text-center'>

                                <IonText className='rec-text'>THANK YOU!</IonText>
                                <br/><br/>
                                <IonText className='rec-foot'>For questions or any other inquiries please contact us at: {phone} or {mail}</IonText>
                            </IonCol>
                    </IonRow>

                  
                </IonGrid>

            </IonContent>
          
        </IonPage>
    );
};

export default ReceiptDetail;
